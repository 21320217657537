<template>
  <v-container fluid class="mt-0 pa-1">
    IMPUESTOS:
    <p>Monto: {{ currencyFormat(total) }}</p>
    <v-expansion-panels multiple class=" condensed my-1">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Impuestos: {{ currencyFormat(total) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Conceptos</th>
                <th>Porcentaje</th>
                <th>Costo Unitario</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in datosPrincipales.impuestos"
                :key="i"
                v-bind:class="{ 'bg-gris': item.type === 4 }"
              >
                <td>{{ item.name }}</td>
                <td>
                  <v-text-field
                    :readonly="!editarflag && tipo == 'ver'"
                    v-if="item.type != 4 && item.type != 1 && item.type != 2"
                    class="derecha"
                    type="number"
                    min="0"
                    step="0.01"
                    hide-details
                    v-model="item.percentage"
                    @keyup="actualizarImpuesto"
                    style="text-align: right !import"
                    suffix="%"
                    width="5px"
                  ></v-text-field>
                </td>
                <td class="derecha">
                  {{ currencyFormat(item.valor) }}
                </td>
              </tr>
              <tr class="bg-total">
                <td>TOTAL</td>
                <td></td>
                <td class="derecha">{{ currencyFormat(total) }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import funcion from "@/mixins/funciones";
export default {
  mixins: [funcion],
  props: ["tipo"],
  data() {
    return {
      dialog: false,
      dialogCosto: false,
      dialogVenta: false,
      panel: [0],
      opciones: [
        {
          id: "FGO",
          value: "FGO",

          text: " Flete y Gastos de origen",
        },
        {
          id: "GLN",
          value: "GLN",
          text: "Gastos Locales Navieros",
        },
        {
          id: "AS",
          value: "AS",

          text: "Aduanas",
        },
        {
          id: "AL",
          value: "AL",

          text: "Almacén",
        },
      ],

      headerImpuesto: [],
      items: [],
      totalOption: 0.0,
      totalVenta: 0,
      resumenOpcion: {
        flete: 0,
        gasto: 0,
        aduana: 0,
        amacen: 0,
      },
      resumenVenta: {
        flete: 0,
        gasto: 0,
        aduana: 0,
        amacen: 0,
      },
      fromDataService: {
        ventaFlag: 0,
        status: 1,
        statedelete: 0,
        id_begend: 0,
      },
      total: 0,
    };
  },
  mounted() {
    this.actualizarImpuesto();
  },
  created() {},
  methods: {
    ...mapMutations([
      "setEditarflag",
      "updateDatosNotas",
      "setCostos",
      "setTotalImpuesto",
    ]),

    actualizarImpuesto() {
      let fob = null;
      let flet = null;
      let seguro = null;
      let cif = null;
      let valor = null;
      let adv = null;
      let isc = null;
      let igv = null;
      let ipm = null;
      this.total = 0;

      this.datosPrincipales.impuestos.forEach((element) => {
        valor = 0;
        switch (element.type) {
          case 1:
            valor = this.datosPrincipales.amount;
            fob = valor;
            break;
          case 2:
            valor = this.totalDeFlete;
            flet = valor;
            break;
          case 3:
            valor =
              ((parseFloat(this.totalDeFlete) +
                parseFloat(this.datosPrincipales.amount)) *
                element.percentage) /
              100;
            seguro = valor;
            break;
          case 4:
            valor = parseFloat(fob) + parseFloat(flet) + parseFloat(seguro);
            cif = valor;
            break;
          case 5:
            valor = (cif * element.percentage) / 100;
            adv = valor;
            this.total += valor;
            break;
          case 6:
            valor = ((cif + adv) * element.percentage) / 100;
            isc = valor;
            this.total += valor;
            break;
          case 7:
            valor = ((cif + adv + isc) * element.percentage) / 100;
            this.total += valor;
            ipm = valor;
            break;
          case 8:
            valor = ((cif + adv + isc) * element.percentage) / 100;
            this.total += valor;
            igv = valor;
            break;
          case 9:
            valor = ((cif + adv + isc + igv + ipm) * element.percentage) / 100;
            this.total += valor;
            break;
          // break;
          default:
            break;
        }
        element.valor = valor;
      });

      this.setTotalImpuesto(this.total);
    },

    aceptar() {
      this.updateDatosNotas();
    },

    guardarCostoVenta() {
      if (this.$refs.nuevoCostoVenta.validate()) {
        switch (this.fromDataService.idOpcion) {
          case "FGO":
            this.fromDataService.id_begend = 1;
            break;
          case "GLN":
            this.fromDataService.id_begend = 2;
            break;
          case "AS":
            this.fromDataService.id_begend = 2;
            break;
          case "AL":
            this.fromDataService.id_begend = 2;
            break;

          default:
            break;
        }
        this.fromDataService.ventaFlag = 1;
        this.costos.push(this.fromDataService);
        this.setCostos(this.costos);
        this.calcTotalVenta();
        this.$refs.nuevoCostoVenta.reset();
        this.dialogVenta = false;
      }
    },
  },

  computed: {
    ...mapState([
      "datosPrincipales",
      "begEndList",
      "roles",
      "multiplicador",
      "costos",
      "factor",
      "totalDeFlete",
      "impuestoFlag",
      "editarflag",
    ]),
  },
  beforeMount() {
    if (this.tipo == "editar") {
      this.$store.editarflag == true;
      this.setEditarflag(true);
    } else {
      this.setEditarflag(false);
    }
  },
  watch: {
    totalDeFlete() {
      this.actualizarImpuesto();
    },
    datosPrincipales() {
      this.actualizarImpuesto();
    },
    impuestoFlag() {
      this.actualizarImpuesto();
    },
  },
};
</script>
<style scoped>
.v-expansion-panels.condensed .v-expansion-panel-header {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: auto;
}
.widthTD {
  width: 150px !important;
}

/* .tdMontos {
  width:  !important; 
} */

td {
  padding: 0 3px !important;
  font-size: 5rem;
}
.derecha {
  text-align: right !important;
  align-content: right !important;
}
.v-text-field__slot {
  max-width: 5px !important;
}
.bg-gris {
  background: rgb(46, 46, 46);
  color: #fff;
}
.v-text-field {
  padding: 0;
  margin: 0;
}
.bg-total {
  background: rgb(8, 0, 80);
  color: #fff;
}
</style>
